<template lang="pug">
  v-container(fluid)
    //- Dialog
    //- Dialog profile
    v-dialog(v-if="dialogProfile" v-model="dialogProfile" persistent :fullscreen="isMobile" width="700" hide-overlay overlay-color="blue lighten-5" transition="dialog-bottom-transition" style="max-height:100vh; overflow-y:auto")
      profile-dialog(@close="closeProfile" style="z-index:1")
    //- Complete dialog
    v-dialog(v-if="completeProfileDialog" v-model="completeProfileDialog" :fullscreen="isMobile" persistent width="500" hide-overlay transition="dialog-bottom-transition" )
      complete-profile(@close="closeComplete" style="z-index:1")
    //- Dialog how use
    v-dialog(v-model="howUseDialog" :fullscreen="isMobile" transition="dialog-bottom-transition")
      how-use(@close="closeHow" :modal="true" style="background: white; height:100%; z-index:999").pa-10
    //- Drawer
    v-navigation-drawer#core-navigation-drawer.custom-drawer( v-model="drawer" :light="isMobile" :dark="!isMobile" :expand-on-hover="expandOnHover" :src="barImage" mobile-break-point="960" app="" mini-variant-width="80" :width='windowWidth < 600 ? windowWidth : 320' v-bind="$attrs")
      template(v-slot:img="props")
        v-img(:gradient="`to bottom, ${barColor}`" v-bind="props")
      //- drawer header
      v-list-item(two-line="")
        v-list-item-content
          v-list-item-title.text-uppercase.font-weight-regular.display-2
            span.logo-mini {{ $t('sf') }}
            span.logo-normal {{ $t('title') }}
            .close-button.cursor-pointer(@click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)")
              v-icon mdi-close-circle
      v-divider.mb-1
      //- profile
      v-list(v-if="user" dense="" nav="")
        base-item(head :profile='profile' :item="itemsProfile" color="black")
      v-list(v-else )
        v-list-item( @click.native="openDialog('login')" style="position:relative; top:30%")
          v-list-item-icon
            v-icon mdi-login
          v-list-item-content
            v-list-item-title.v-list-item__text--main(:class="isMobile ? 'black--text':''") Log In
      v-divider.mb-2
      v-list(expand="" nav="" style="height:50%")
        //- items si es en mobile
        template(v-if="isMobile" v-for="(item, i) in itemsMobile")
          div
            base-item(:key="`item-${i}`" :item="item")
        //- Cambiar perfil
        v-list-item(v-if="isMobile && user" to="/contributor")
          v-list-item-icon
            img(src="@/assets/icons/swap.png" width="auto" height="25px").bright
          v-list-item-content
            v-list-item-title.v-list-item__text--main.black--text Become a Contributor
        //-logout
        v-list-item(v-if="isMobile && user" @click="logout")
          v-list-item-icon
            img(src="@/assets/images/icon-logout.png" width="auto" height="25px").bright
          v-list-item-content
            v-list-item-title.v-list-item__text--main.black--text Log out
        //- items si es en desktop
        template(v-if="!isMobile" v-for="(item, i) in itemsDesktop")
          div(style='position: relative; top:30%')
            base-item(:key="`item-${i}`" :item="item" style="position:relative; top:30%")
            v-alert.tutorial-alert(v-if="user && profile && profile.tutorialCustomer && !isMobile && i === stepsTutorial" :color="tutorial[stepsTutorial].color")
              v-row.pr-3
                h3.headline {{tutorial[stepsTutorial].title}}
                v-spacer
                v-btn(@click="stepsTutorial = 10" small icon).ml-auto.align-start
                  v-icon mdi-close
              div(style="z-indeX:99")
                | {{tutorial[stepsTutorial].description}}
              v-checkbox(v-if="tutorial.length-1 === stepsTutorial" v-model="showTutorial" label="Do not show this again" color="red" value="red" hide-details="")
              div.text-right
                v-btn(v-if="stepsTutorial > 0" color="white" text small @click="backStep")
                  | prev
                v-btn(color="white" text small @click="changeStep")
                  span(v-if="tutorial.length-1 === stepsTutorial") Finish
                  span(v-else) next
</template>

<script>
  import loaders from '@/plugins/mixins/loaders'
  import profileDialog from '../../../../components/custom/dialogs/profileDialog'
  import { auth } from '@/plugins/auth/firebase'
  import Cookie from 'js-cookie'
  // Utilities
  export default {
    name: 'DashboardCoreDrawer',

    components: {
      profileDialog: profileDialog,
      completeProfile: () => import('@/components/custom/dialogs/completeProfileDialog'),
      login: () => import('@/views/pages/Login'),
      register: () => import('@/views/pages/Register'),
      howUse: () => import('@/components/custom/HowToUse'),
    },

    mixins: [
      loaders,
    ],

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      dialogProfile: false,
      completeProfileDialog: false,
      loginDialog: false,
      registerDialog: false,
      howUseDialog: false,
      showTutorial: false,
      tutorial: [
        { title: 'Home', description: 'This is where you can conduct your search and review your most recent content.', color: 'info' },
        { title: 'Purchased Content', description: 'Find all content that was purchased using your account here.', color: 'warning' },
        { title: 'Search', description: 'Launch a search using main categories or sub-categories with filtering.', color: 'error' },
        { title: 'Notifications', description: 'This is where all your latest notifications that requires attention can be found.', color: 'success' },
        { title: 'Collaborators', description: 'Add unlimited collaborators to your account from this tab.', color: 'info' },
        { title: 'Plans', description: 'Manage your subscription plan here. You can upgrade if you need to.', color: 'warning' },
        { title: 'Become a Contributor', description: 'Here you can opt in to become a contributor even if you’re a customer.', color: 'warning' },
      ],
      stepsTutorial: 0,
    }),

    computed: {
      windowWidth () {
        return window.innerWidth
      },

      barColor () {
        return this.$store.state.drawer.barColor
      },

      barImage () {
        return this.$store.state.drawer.barImage
      },
      profile () {
        return this.$store.state.users.profile
      },
      user () {
        return this.$store.state.users.user
      },

      isMobile () {
        return this.$store.getters['users/getIsMobile']
      },

      itemsProfile () {
        return {
          altImg:  this.profile && this.profile.image  ? this.profile.image : require('@/assets/user.png'),
          icon: 'mdi-account',
          title: 'My Profile',
          to: '/profile',
        }
      },

      itemsDesktop () {
        if (this.user) {
          return [
            {
              altImg: require('@/assets/images/search-icon.png'),
              icon: 'mdi-view-dashboard',
              title: 'Home',
              to: '/',
            },
            {
              altImg: require('@/assets/images/icon_video.png'),
              icon: 'mdi-view-dashboard',
              title: 'Purchased Content',
              to: '/videos',
            },
            {
              altImg: require('@/assets/images/icon3.png'),
              icon: 'mdi-view-dashboard',
              title: 'Search',
              to: '/search',
            },
            {
              altImg: require('@/assets/images/icon4.png'),
              icon: 'mdi-view-dashboard',
              title: 'Notifications',
              to: '/notifications',
            },
            {
              altImg: require('@/assets/images/icon-usertype.png'),
              icon: 'mdi-view-dashboard',
              title: 'Collaborators',
              to: '/collaborators',
            },
            {
              altImg: require('@/assets/images/plans.png'),
              icon: 'mdi-view-dashboard',
              title: 'Plans',
              to: '/Plans',
            },
            {
              altImg: require('@/assets/icons/swap.png'),
              icon: 'mdi-view-dashboard',
              title: 'Become a Contributor',
              to: '/contributor',
            },
          ]
        } else {
          return [
            {
              altImg: require('@/assets/images/search-icon.png'),
              icon: 'mdi-view-dashboard',
              title: 'Home',
              to: '/',
            },
            {
              altImg: require('@/assets/images/icon3.png'),
              icon: 'mdi-view-dashboard',
              title: 'Map',
              to: '/search',
            },
          ]
        }
      },

      itemsMobile () {
        if (this.user) {
          return [
            {
              altImg: require('@/assets/images/icon-faq.png'),
              icon: 'mdi-view-dashboard',
              title: "FAQ's",
              to: '/faq',
            },
            {
              altImg: require('@/assets/images/icon-about.png'),
              icon: 'mdi-view-dashboard',
              title: 'About Us',
              to: '/about-us',
            },
            {
              altImg: require('@/assets/images/icon-contact.png'),
              icon: 'mdi-view-dashboard',
              title: 'Contact Us',
              to: '/contact-us/contact',
            },
            {
              altImg: require('@/assets/images/icon-usertype.png'),
              icon: 'mdi-view-dashboard',
              title: 'Collaborators',
              to: '/collaborators',
            },
            {
              altImg: require('@/assets/images/plans.png'),
              group: '/plans',
              icon: 'mdi-coin',
              title: 'Plans',
              to: '/Plans',
            },
          ]
        } else {
          return [
            {
              altImg: require('@/assets/images/icon-faq.png'),
              icon: 'mdi-view-dashboard',
              title: "FAQ's",
              to: '/faq',
            },
            {
              altImg: require('@/assets/images/icon-about.png'),
              icon: 'mdi-view-dashboard',
              title: 'About Us',
              to: '/about-us',
            },
            {
              altImg: require('@/assets/images/icon-contact.png'),
              icon: 'mdi-view-dashboard',
              title: 'Contact Us',
              to: '/contact-us/contact',
            },
          ]
        }
      },
      drawer: {
        get () {
          return this.$store.state.drawer.drawer
        },
        set (val) {
          this.$store.dispatch('drawer/set_drawer', val)
        },
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },

      user: function (newVal) {
        if (newVal) {
          this.getProfile()
          this.loadStripe()
        }
      },
    },

    created () {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
      if (this.user) {
        this.getProfile()
        this.loadProfile()
        this.loadStripe()
      }
    },

    destroyed () {
      window.removeEventListener('resize', this.handleResize)
    },

    methods: {
      backStep () {
        const vm = this
        vm.stepsTutorial--
      },
      changeStep () {
        const vm = this
        vm.stepsTutorial++
        if (vm.stepsTutorial >= vm.tutorial.length) {
          vm.stepsTutorial = 8
          if (vm.showTutorial) {
            const profile = {
              tutorialCustomer: false,
            }
            vm.$store.dispatch('users/updateProfile', profile)
          }
        }
      },
      switchDialog (to) {
        const vm = this
        switch (to) {
          case 'login':
            vm.registerDialog = false
            vm.loginDialog = true
            break
          case 'register':
            vm.loginDialog = false
            vm.registerDialog = true
            break
        }
      },
      openDialog (dialog) {
        const vm = this
        vm.$store.dispatch('dialogs/openDialog', dialog).then(function () {
          // vm.setDrawer(false)
          // // console.log('dialog set on : ' + dialog)
        })
      },

      closeProfile () {
        this.dialogProfile = false
        this.howUseDialog = true
      },
      closeComplete () {
        this.completeProfileDialog = false
        this.howUseDialog = true
      },
      closeHow () {
        this.howUseDialog = false
        this.setDrawer(true)
      },
      async logout () {
        const vm = this
        // console.log('logout')
        await auth.signOut()
        await Cookie.remove('access_token')
        vm.$store.dispatch('users/clearUser').then(function () {
          vm.$router.replace('/')
        })
      },
      setDrawer (payload) {
        this.$store.dispatch('drawer/set_drawer', payload)
      },
      handleResize () {
        if (window.innerWidth <= 720) {
          this.$store.commit('users/SET_MOBILE', true)
        } else {
          this.$store.commit('users/SET_MOBILE', false)
        }
      },
      validateProfile (profile) {
        const vm = this
        // console.log(profile)
        if (!profile || !profile.first_name) {
          vm.dialogProfile = true
          // vm.setDrawer(false)
        }
      },
      getProfile () {
        const vm = this
        vm.$store.dispatch('users/loadProfile').then(function (res) {
          vm.validateProfile(res)
        })
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
